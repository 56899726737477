import React, { useState } from "react";
import { Link } from "gatsby";
import tw, { styled } from "twin.macro";
import LogoSvg from "./logo.svg";

import arrowDownSrc from "../../images/expand_more_FILL0_wght400_GRAD0_opsz24.svg";

const SkipLink = tw.a`sr-only focus:not-sr-only block bg-blue-50 font-bold p-2! w-full`;
const SkipLinkTarget = tw.span`sr-only`;
const MenuButton = tw.button`z-50 fixed right-0 top-0 transition ease-in-out duration-300 bg-blue-900 text-sm rounded-bl-xl hover:shadow-md focus:shadow-md hover:bg-blue-700 focus:bg-blue-700 cursor-pointer text-white p-4 md:hidden ml-4`;
const CloseButton = tw.button`self-end text-3xl md:hidden font-bold p-4 text-gray-500 hover:text-gray-700 focus:text-gray-700 block`;
const NavbarContainer = tw.nav`p-4 flex flex-row md:flex-col lg:flex-row justify-between items-center`;
const StyledNavbarLink = tw(
  Link
)`flex md:inline-flex space-x-2 items-center font-bold text-gray-500 border-b-4 border-transparent hover:text-blue-700 focus:text-blue-700 transition ease-in-out duration-300`;
const NavbarLinks = styled.div`
  ${({ isOpen }) => (!isOpen ? tw`invisible opacity-0` : tw`opacity-100 flex`)}
  ${tw`flex-col items-start justify-between transition duration-300 w-full md:w-auto fixed shadow-lg bg-white md:bg-transparent h-screen z-50 top-0 right-0 md:shadow-none md:h-auto md:static md:block md:visible md:opacity-100`}
`;
const NavbarLogo = tw(Link)`md:mb-4 lg:mb-0`;
const SignInButton = tw.a`mb-4 md:inline-block bg-blue-900 text-white py-2 px-3 rounded transition ease-in-out duration-300 hover:shadow-lg focus:shadow-lg hover:bg-blue-700 focus:bg-blue-700`;
const Logo = tw.img`h-24`;
const NavRow = tw.div`px-2 md:px-0 space-y-8 md:space-y-0 md:space-x-8 flex flex-col md:flex-row md:justify-center lg:justify-end items-start`;

const NavbarSubmenu = tw.div`w-56 right-0 space-y-4 md:space-y-0 flex md:hidden md:absolute group-hover:flex flex-col bg-white md:rounded md:shadow-xl`;
const StyledNavbarSubmenuLink = tw(
  Link
)`block px-8 first:mt-2 last:mb-2 md:mx-2 md:p-2 text-base hocus:bg-blue-700 hocus:text-white rounded transition duration-100 ease-in-out`;
const NavbarMenu = tw.div`relative block md:my-0 md:inline-block border-blue-900 transition ease-in-out duration-300`;

const NavbarLink = (props) => (
  <StyledNavbarLink
    {...props}
    partiallyActive
    activeStyle={tw`font-bold border-b-4 text-blue-900 border-blue-900 `}
  />
);

const NavbarSubmenuLink = (props) => (
  <StyledNavbarSubmenuLink
    {...props}
    activeStyle={tw`font-bold hocus:text-white`}
  />
);

const Navbar = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <SkipLink href="#end-navigation">Skip navigation</SkipLink>
      <NavbarContainer>
        <NavbarLogo to="/">
          <Logo src={LogoSvg} alt="CP-CTNet" />
        </NavbarLogo>
        <MenuButton onClick={() => setOpen(!open)}>Navigation</MenuButton>
        <NavbarLinks isOpen={open}>
          <CloseButton onClick={() => setOpen(!open)}>&times;</CloseButton>
          <NavRow>
            <SignInButton href="/portal">Member&nbsp;Signin</SignInButton>
          </NavRow>
          <NavRow>
            <NavbarLink to="/our-team">Our Team</NavbarLink>
            <NavbarLink to="/trials">Trials</NavbarLink>
            <NavbarMenu className="group">
              <NavbarLink to="/program-resources">
                <span>Resources</span>
                <img width="16" src={arrowDownSrc} alt="" />
              </NavbarLink>
              <NavbarSubmenu>
                <NavbarSubmenuLink to="/program-resources">
                  Program Resources
                </NavbarSubmenuLink>
                <NavbarSubmenuLink to="/program-resources/lao-resources">
                  LAO Resources
                </NavbarSubmenuLink>
              </NavbarSubmenu>
            </NavbarMenu>
            <NavbarMenu className="group">
              <NavbarLink to="/updates">
                <span>Updates</span>
                <img width="16" src={arrowDownSrc} alt="" />
              </NavbarLink>
              <NavbarSubmenu>
                <NavbarSubmenuLink to="/updates">News</NavbarSubmenuLink>
                <NavbarSubmenuLink to="/updates/newsletters">
                  Newsletters
                </NavbarSubmenuLink>
                <NavbarSubmenuLink to="/updates/events">
                  Meetings and Events
                </NavbarSubmenuLink>
                <NavbarSubmenuLink to="/updates/funding-opportunities">
                  Funding Opportunities
                </NavbarSubmenuLink>
              </NavbarSubmenu>
            </NavbarMenu>
            <NavbarMenu className="group">
              <NavbarLink to="/contact">
                <span>Contact</span>
                <img width="16" src={arrowDownSrc} alt="" />
              </NavbarLink>
              <NavbarSubmenu>
                <NavbarSubmenuLink to="/contact">
                  Organizational Contacts
                </NavbarSubmenuLink>
                <NavbarSubmenuLink to="/contacts-directory">
                  Contact Directory
                </NavbarSubmenuLink>
              </NavbarSubmenu>
            </NavbarMenu>
          </NavRow>
        </NavbarLinks>
      </NavbarContainer>
      <SkipLinkTarget id="end-navigation">End of navigation</SkipLinkTarget>
    </>
  );
};

export default Navbar;
