// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contacts-directory-js": () => import("./../src/pages/contacts-directory.js" /* webpackChunkName: "component---src-pages-contacts-directory-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-team-js": () => import("./../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-program-resources-index-js": () => import("./../src/pages/program-resources/index.js" /* webpackChunkName: "component---src-pages-program-resources-index-js" */),
  "component---src-pages-program-resources-lao-resources-js": () => import("./../src/pages/program-resources/lao-resources.js" /* webpackChunkName: "component---src-pages-program-resources-lao-resources-js" */),
  "component---src-pages-trials-index-js": () => import("./../src/pages/trials/index.js" /* webpackChunkName: "component---src-pages-trials-index-js" */),
  "component---src-pages-trials-view-js": () => import("./../src/pages/trials/view.js" /* webpackChunkName: "component---src-pages-trials-view-js" */),
  "component---src-pages-updates-events-js": () => import("./../src/pages/updates/events.js" /* webpackChunkName: "component---src-pages-updates-events-js" */),
  "component---src-pages-updates-funding-opportunities-archive-js": () => import("./../src/pages/updates/funding-opportunities/archive.js" /* webpackChunkName: "component---src-pages-updates-funding-opportunities-archive-js" */),
  "component---src-pages-updates-funding-opportunities-index-js": () => import("./../src/pages/updates/funding-opportunities/index.js" /* webpackChunkName: "component---src-pages-updates-funding-opportunities-index-js" */),
  "component---src-templates-contact-template-js": () => import("./../src/templates/contactTemplate.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-news-list-template-js": () => import("./../src/templates/newsListTemplate.js" /* webpackChunkName: "component---src-templates-news-list-template-js" */),
  "component---src-templates-news-template-js": () => import("./../src/templates/newsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-newsletter-list-template-js": () => import("./../src/templates/newsletterListTemplate.js" /* webpackChunkName: "component---src-templates-newsletter-list-template-js" */),
  "component---src-templates-newsletter-template-js": () => import("./../src/templates/newsletterTemplate.js" /* webpackChunkName: "component---src-templates-newsletter-template-js" */)
}

